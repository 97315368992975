
export const CargasOptions = [
    'PodeAlterarSituacao',
    'PodeIncluirPedidos',
    'PodeEntregarPedidos',
    'SomenteCargasEntregador',
    'PodeImprimir'
];

export const CargasDep = [
    { resource: 'estoques', permissions: { list: true } },
    { resource: 'pessoas', permissions: { list: true } },
    { resource: 'entregarpedido', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'rotas', permissions: { list: true, } },
    { resource: 'veiculos', permissions: { list: true, } },
];