import { RecordBase, Schema } from '../../../core/types';

export const EstoqueSchema: Schema<Estoque> = {
    fields: [
        { name: "Id", dataType: "integer", primaryKey: true, displayLabel: '#', fieldSize: 1, alignment:'center',
            getValue(text, value) {
                return '#' + value;
            },
        },
        { name: "Nome", dataType: "string", displayLabel: "Nome", fieldSize: 95, sortable: true, searchable: true, required: true },
        {
            name: "CentroCustoLucroId", dataType: "integer", displayLabel: "Centro de Custo/Lucro", hidden: true, required: true, export: true,
            getOptions: async (record, field, search, dataset) => {
                const r = await dataset.api.get('centroscustolucro', false, '', false, 'Nome');
                return r.rows;
            },
            optionsText: 'Nome', optionsKey: 'Id', cacheOptions: true,
        },
        { name: "Ativo", dataType: "bool", displayLabel: "Ativo", fieldSize: 5, alignment: "center" },
        {
            name: "TabelaId", dataType: "integer", displayLabel: "Tabela de Preço Padrão", hidden: true, required: false, ignoreArrange: true,
            getOptions: async (record, field, search, dataset) => {
                const r = await dataset.api.get('tabelaspreco', false, '');
                return r.rows;
            },
            optionsText: 'Nome', optionsKey: 'Id', cacheOptions: true,
        },
    ],

    onNewRecord: (record) => {
        record.Ativo = true;
        record.CentroCustoLucroId = 1;
    }
};

export type Estoque = RecordBase & {
    Nome: string;
    Ativo: boolean;
    TabelaId: number;
    CentroCustoLucroId: number;
};

export const EstoquesDependencies = [
    { resource: 'centroscustolucro', permissions: { list: true } },
    { resource: 'tabelaspreco', permissions: { list: true } },
];
