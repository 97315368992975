export const options = ['SomenteClientesVendedor', 'GerenciarTabelasPreco', 'BloquearMetodoPagtoCliente', 'PermitirImprimir', 'PermitirImpCurvaABC', 'PermitirEnviarMsgWhatsApp'];

export const dependencies= [
    { resource: 'lancamentoscaixa', permissions: { list: true } },
    { resource: 'contas', permissions: { list: true } },
    { resource: 'tabelaspreco', permissions: { list: true } },
    { resource: 'metodospagamento', permissions: { list: true } },
    { resource: 'categoriasfinanceiras', permissions: { list: true } },
    { resource: 'realizarlancamento', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'contratos', permissions: { list: true } },
    { resource: 'rotas', permissions: { list: true } },
    { resource: 'ecsenha', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'gruposclientes', permissions: { list: true, } },
]