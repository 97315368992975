import { makeStyles, createStyles } from '@mui/styles';
import { CustomTheme } from '../../../services/Theme/types';
import { color } from 'html2canvas/dist/types/css/types/color';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        BoxContent: {
            minHeight: '100%',
            padding: 20
        },
        TitleButton: {
            marginLeft: 12,
            padding: 0,
            minWidth: '42px !important',

            '& .MuiButton-icon': {
                color: theme.palette.primary.contrastText,
            },
            // padding: '8px 12px 8px 12px',
        },
        Icon: {
            padding: 0,
            margin: 0,
        },

        Shrink: {
            fontSize: '0.75rem !important',
            height: 32
        },

        SizeSmall: {
            height: 32,
            paddingTop: '3px !important',
        },

        SizeMedium: {
            height: 36
        },

        Button: {
            fontSize: '0.9rem',
            color: theme.palette.primary.contrastText,


        },
        rootRedButton: {
            backgroundColor: theme.palette.error.dark + ' !important',
            color: theme.palette.getContrastText(theme.palette.error.dark) + ' !important',
            borderColor: theme.others.negativeValue + ' !important',
            '&:hover': {
                backgroundColor: theme.palette.error.light + ' !important',
            },
            '&:disabled': {
                color: theme.palette.getContrastText(theme.others.negativeBackground) + ' !important',
            }
        },

        rootGreenButton: {
            backgroundColor: theme.others.greenButton + ' !important',
            color: theme.palette.getContrastText(theme.palette.error.dark) + ' !important',
            borderColor: theme.others.positiveValue + ' !important',
            '&:hover': {
                backgroundColor: theme.others.greenButtonHover + ' !important',
            },
            '&:disabled': {
                backgroundColor: theme.others.greenButtonDisabled + ' !important',
            }
        },

        defaultButton: {
            // backgroundColor: theme.palette.grey[100] + ' !important',
            color: theme.others.iconColor + ' !important',

            '& .MuiButton-icon ': {
                color: theme.others.iconColor + ' !important',
            },

            // borderColor: theme.others.positiveValue + ' !important',
            // '&:hover': {
            //     backgroundColor: theme.others.greenButtonHover + ' !important',
            // },
            // '&:disabled': {
            //     backgroundColor: theme.others.greenButtonDisabled + ' !important',
            // }
        }


    }),
);

export default useStyles;