import { lazy } from 'react';
import { LocalShipping, Map, RvHookupSharp } from '@mui/icons-material';
import { Module } from '../core/types';

// ############### props from routes

import { dependencies as SalesRouteDep, options as SalesRouteOpts } from '../pages/Logistica/Rotas/route.props';
import LogisticsIcon from '@src/icons/LogisticsIcon';
import { CargasDep, CargasOptions } from '@src/pages/Logistica/Cargas/route.props';

// ############### Elements of Routes

const Cargas = lazy(() => import('../pages/Logistica/Cargas'));
const Rotas = lazy(() => import('../pages/Logistica/Rotas'));
const Fornecedores = lazy(() => import('../pages/Estoque/Fornecedores'));
const Veiculos = lazy(() => import('../pages/Logistica/Veiculos'));

// ############### Routes

export const BR_Logistica: Module = {
    name: 'logistica',
    routes: [
        {
            name: 'logistica', icon: <LogisticsIcon />,
            children: [
                { name: 'cargas', element: <Cargas />, icon: <LocalShipping />, resource: 'cargas', internalGroup: 'logistica', internalName: 'cargas', dependencies: CargasDep, options: CargasOptions },
                { name: 'rotas', element: <Rotas />, icon: <Map />, resource: 'rotas', dependencies: SalesRouteDep, options: SalesRouteOpts, internalName: 'rotas' },
                { name: 'transportadoras', element: <Fornecedores key="transportadoras" nome='transportadoras' grupo='logistica' tipo='L' />, resource: 'pessoas', icon: <RvHookupSharp />, internalGroup: 'logistica', internalName: 'transportadoras', },
                { name: 'veiculos', element: <Veiculos />, resource: 'veiculos', internalGroup: 'logistica', internalName: 'veiculos' },
            ]
        },

    ],
}