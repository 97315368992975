import { Helmet } from 'react-helmet';
import { Button, Typography, useMediaQuery } from '@mui/material';
import Config from '../../../config';
import { useState } from 'react';
import { useApi } from '../../services/Api';
import { useLang } from '../../services/Lang';
import Row from '../../components/ui/Row';
import Col from '../../components/ui/Col';
import { ReactComponent as Beach } from '../../../icons/beach.svg';
import { useTheme } from '../../services/Theme';
import { useTheme as useThemeCore } from '@mui/material/styles';

const SlowDown = () => {
    const [lastPath] = useState(() => localStorage.getItem('lastPath') || '/');
    const api = useApi();
    const { lang } = useLang();
    const { theme } = useTheme();

    const themeCore = useThemeCore();
    const hideSmDown = !useMediaQuery(themeCore.breakpoints.down('sm'));
    const hideMdUp = !useMediaQuery(themeCore.breakpoints.up('md'));


    const doReconnect = () => {
        localStorage.setItem('lastPath', '');
        window.location.pathname = lastPath;
    }

    return (
        <>
            <Helmet>
                <title>{api.isStandAlone ? '' : (' | ' + Config.name)}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
            </Helmet>
            <Row style={{ padding: 12, alignItems: 'center' }}>
                {hideSmDown && <>
                    <Col sm={12} md={6} style={{ padding: 36, display: 'flex', justifyContent: 'center' }}>
                        <Beach style={{ maxWidth: 512 }} />
                    </Col>

                    <Col sm={12} md={6}>
                        <Typography style={{ margin: 24, fontSize: '5rem', lineHeight: 1 }}>Hey!! Slow down!!</Typography>
                        <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Você está indo rápido demais! Que tal aguardar um tempo? Você poderá voltar a acessar o sistema em 10 segundos.</Typography>
                        <Button variant='contained' color='primary' style={{ margin: 24 }}>Voltar</Button>
                    </Col>
                </>}

                {hideMdUp && <>
                    <Col sm={12} md={6} style={{ textAlign: 'center' }}>
                        <Typography style={{ margin: 24, fontSize: '3rem', lineHeight: 1 }}>Hey!! Slow down!!</Typography>
                        <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Você está indo rápido demais! Que tal aguardar um tempo? Você poderá voltar a acessar o sistema em 10 segundos.</Typography>
                        <Button variant='contained' color='primary' style={{ margin: 24 }} onClick={doReconnect}>Voltar</Button>
                    </Col>
                    <Col sm={12} md={6} style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                        <Beach style={{ maxWidth: 360, maxHeight: 360 }} />
                    </Col>
                </>}
            </Row >
        </>
    )
};

export default SlowDown;
