import { RouteArray } from "../core/types";
import { Api } from "../core/services/Api/Api";
import { RoutesHashTable } from "../core/services/UserRouter";

export type Permissions = {
    list?: boolean;
    record?: boolean;
    insert?: boolean;
    update?: boolean;
    delete?: boolean;
}

export type UserPermissions = UserPermission[];

export interface UserPermission {
    resource: string;
    permissions: Permissions;
    permissionsValue?: number;
    module?: string;
    options?: string[];
}

export interface Empresa {
    NomeEmpresa: string;
    NomeFantasia: string;
    Endereco: string;
    Numero: string;
    Complemento: string;
    Bairro: string;
    PaisId: number;
    PaisNome: string;
    CidadeId: number;
    CidadeNome: string;
    EstadoId: number;
    EstadoNome: string;
    EstadoSigla: string;
    CEP: string;
    Telefone1: string;
    Telefone2: string;
    Email: string;
    CpfCnpj: string;
    InscricaoEstadual: string;
    TipoLogo: number;
    LogoURL: string;
    LogoPNG?: string;
}

export interface Notification {
    _createdAt: Date
    Code: number
    Path: string
    Title: string
    Message: string
    Read: boolean
    Value: string
}

export type OpenBill = {
    id: number
    dueDate: Date
    amount: number
    boleto: string
    pix: string
}

export interface TenantInfo {
    id?: number
    tenant?: string
    name?: string
    Active?: boolean
    api?: string
    lang?: boolean
    DbStorage?: number
    FileStorage?: number
    loading?: boolean
    CustomDomain?: string
    CostumerId?: string
    SuspensionCheck?: boolean
}

export interface NotificationInfo {
    unread: number
    total: number
    list: Notification[]
}

export type OptBool = boolean | 0 | 1;

export type SystemOptions =  {
    [key: string]: any;

    EstoquePadrao?: number;

    MostrarVencTabela?: OptBool;
    ObsClienteImpressao?: OptBool;
    MostrarLoteImpressao?: OptBool;
    TamanhoFonteImpressao?: number;
    AssinaturaEntrega?: OptBool;
    SeparacaoPedidos?: OptBool;
    ObrigarSeparacaoPedidos?: OptBool;
    AddPacoteSeparacao?: OptBool;
    MostrarSKUImpressao?: OptBool;
    PrecoSugCapt?: OptBool;
    NaoMostrarRodape?: OptBool;
    MsgSales?: string;
    MultiplosCodBarras?: OptBool;
    PesoProd?: OptBool;
    AtualizarPreco?: number;
    LucroFixo?: OptBool;
    LucroMinimoFixo?: OptBool;

    RegimeTributario?: number;

    TelefoneWhatsAppTeste?: string | null;
    WhatsAppConectado?: OptBool;

    TipoWhatsApp?: number;
    WamPhoneId?: string;
    WamToken?: string;
    WamPin?: number;

    VendedorEcommerce?: number;

    NFSe_Ambiente?: number;
    NFSe_NaturezaOperacao?: number;
    NFSe_Serie?: string;
}

export class User {
    tenantId: number = 0;
    userId: number = 0;
    tag: number = 0;
    name: string;
    isAdmin: boolean = false;
    permissions: UserPermissions = [];
    routes: RouteArray = [];
    authenticated: boolean = false;
    others: { [key: string]: any };
    additional: any;
    redirected?: boolean;
    systemOptions: SystemOptions;
    modules?: string[];
    defaultRoutes: RouteArray = [];
    notifications: Notification[] = [];
    lastNotification?: Date
    routesHashTable?: RoutesHashTable

    setNotifications?: (notifications: NotificationInfo) => void

    constructor(name: string) {
        this.name = name;
        this.others = {};
        this.systemOptions = {};
    }

    getModule(group: string, module: string): string {
        if (module === '___extras' && !group)
            return module;
        return '/' + (group ? group + '/' : '') + module;
    }

    getPerm(module: string, group: string): UserPermission | undefined {
        if (!this.permissions)
            return undefined
        return this.permissions.find(p => p.module === this.getModule(group, module));
    }

    hasModule(module: string): boolean {
        if (!this.modules)
            return false;
        return !!this.modules.find(m => m === module);
    }

    hasOption(module: string, group: string, option: string, HasOptionIfAdmin: boolean = true) {
        if (this.isAdmin)
            return HasOptionIfAdmin;
        const perm: UserPermission | undefined = this.getPerm(module, group);
        if (!perm || !option || !perm.options)
            return false;
        return perm.options.includes(option);
    }

    canList(module: string, group: string): boolean {
        if (this.isAdmin)
            return true;
        return this.getPerm(module, group)?.permissions?.list || false;
    }

    canDetail(module: string, group: string): boolean {
        if (this.isAdmin)
            return true;
        return this.getPerm(module, group)?.permissions?.record || false;
    }

    canInsert(module: string, group: string): boolean {
        if (this.isAdmin)
            return true;
        return this.getPerm(module, group)?.permissions?.insert || false;
    }

    canUpdate(module: string, group: string): boolean {
        if (this.isAdmin)
            return true;
        return this.getPerm(module, group)?.permissions?.update || false;
    }

    canDelete(module: string, group: string): boolean {
        if (this.isAdmin)
            return true;
        return this.getPerm(module, group)?.permissions?.delete || false;
    }

    static parse(obj: any): User {
        const u = new User(obj?.name || '');
        u.tenantId = obj?.tenantId || 0;
        // u.lang = obj?.lang || false;
        u.userId = obj?.userId || 0;
        u.tag = obj?.Tag || obj?.tag || 0;
        u.isAdmin = obj?.isAdmin || false;
        u.others.empresa = obj?.company || '';
        u.authenticated = obj?.authenticated || false;
        u.additional = obj?.additional;
        u.permissions = obj?.permissions;
        u.systemOptions = obj.systemOptions || {};
        u.modules = obj?.modules || [];
        return u;
    }

    static async getNotifications(full: boolean, api: Api) {
        if (!api.user)
            return { list: [], unread: 0, total: 0 }
        const user = api.user;
        const { list, unread, total }: NotificationInfo = await api.getEx({
            resource: 'notifications',
            conditions: full ? 'full=1' : '',
            ignorePrivatePrefix: true
        });

        if (list.length) {
            const last = new Date(list[0]._createdAt);
            if (user.lastNotification && user.lastNotification.getTime() < last.getTime()) {
                const title = (document.title || '').trim();
                let newTitle = document.title;
                if (title.startsWith('(') && title.includes(')')) {
                    const titleArr = title.split(')');
                    titleArr.shift();
                    newTitle = titleArr.join('').trim()
                }
                if (unread > 0)
                    document.title = '(' + unread.toString() + ') ' + newTitle;
                try {
                    await api.onNewNotification?.();
                } catch (err) {
                    // nothing to do
                }
                (async () => {
                    try {
                        const audio = new Audio('/sound/alarm.wav')
                        await audio.play();
                    } catch (err) {
                        // nothing to do
                    }
                })()

            }
            user.lastNotification = last;
        } else {
            user.lastNotification = new Date();
        }
        return { list, unread, total };
    }
}

export type UserContextType = {
    user: User
    tenantInfo: TenantInfo,
    openBills: OpenBill[]
    notifications: NotificationInfo,
    setUser: (user: User) => void
    setNotifications: (notifications: NotificationInfo) => void
    setOpenBills: (openBills: OpenBill[]) => void
}

// type UserProviderProps = {};

export default User;