import { CSSProperties } from 'react';
import { Grid2, useMediaQuery, useTheme } from '@mui/material';
import { GridSize } from '@mui/material/Grid';

interface ICol {
    lg?: GridSize;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    xl?: GridSize;
    visible?: boolean;
    style?: CSSProperties;
    className?: string;
    children?: any;
}
const Col = ({ lg, xs, sm, md, xl, visible, children, style, className }: ICol) => {

    const themeCore = useTheme();
    const isXL = useMediaQuery(themeCore.breakpoints.up('xl'));
    const isLG = useMediaQuery(themeCore.breakpoints.up('lg')) && !isXL;
    const isMD = useMediaQuery(themeCore.breakpoints.up('md')) && !isLG && !isXL;
    const isSM = useMediaQuery(themeCore.breakpoints.up('sm')) && !isMD && !isLG && !isXL;
    const isXS = !isSM && !isMD && !isLG && !isXL;

    if (xs !== 0)
        xs = xs || 12;
    if (sm !== 0)
        sm = sm || xs || 12;
    if (md !== 0)
        md = md || sm || xs || 12;
    if (lg !== 0)
        lg = lg || md || sm || xs || 12;
    if (xl !== 0)
        xl = xl || lg || md || sm || xs || 12;


    if (xs === 0 && isXS) return null;
    if (sm === 0 && isSM) return null;
    if (md === 0 && isMD) return null;
    if (lg === 0 && isLG) return null;
    if (xl === 0 && isXL) return null;

    return (visible === undefined || visible) && (
        <Grid2
            container
            spacing={2}
            size={{
                xs,
                sm,
                md,
                lg,
                xl,
            }}
            style={style}
            className={className}
            alignContent={'baseline'}
        >
            {children}
        </Grid2>
    );
}

export default Col;