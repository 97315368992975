import Config from '../../../config';
import { NavigateFunction } from 'react-router-dom';
import User from '../../../types/user';
import NotificationService from '../Notifications/notification';
import { composeUrl, doRequest, doRequestEx, doSecureRequest, doRefreshToken } from './funcs'
import { getStorage, isRunningStandalone, setStorage } from '../Utils';
import { HttpMethods } from '../../types';
import { uuidv7 } from 'uuidv7';

export type RequestOptions = {
    resource: string
    method?: HttpMethods
    isPublic?: boolean
    conditions?: string
    data?: any
    contentType?: string
    redirectOnFail?: boolean
}

export type RequestGETOptions = {
    resource: string
    isPublic?: boolean
    conditions?: string
    summarized?: boolean
    orderField?: string
    sort?: string
    page?: number
    limit?: number
    showingFields?: string[]
    hidingFields?: string[]
    ignorePrivatePrefix?: boolean
    showDeleted?: boolean
}


export type RequestPOSTOptions = {
    resource: string
    isPublic?: boolean
    conditions?: string
    data?: any
    contentType?: string
    redirectOnFail?: boolean
}

const GET_TRIES = 3;
export class Api {
    api?: Api;
    user?: User;
    clientId: string = (() => {
        let clientId = getStorage('clientId');
        if (!clientId) {
            clientId = uuidv7();
            setStorage('clientId', clientId);
        }
        return clientId;
    })();
    token: string = '';
    lang: string = '';
    tenant: string = '';
    accB64: string = '';
    tenantId: number = 0;
    expiresAt: number = 0;
    baseUrl: string = '';
    uploads: string = Config.uploads;
    isStandAlone?: boolean
    notificationsService: NotificationService
    onNewNotification?: () => any
    onGetNotified?: () => any

    navigate?: NavigateFunction;

    constructor() {
        this.api = this;
        this.isStandAlone = isRunningStandalone();
        this.notificationsService = new NotificationService();
        this.notificationsService.api = this;
    }

    async refreshToken(all?: boolean) {
        return await doRefreshToken(this, !!all)
    }

    async get(resource: string, isPublic: boolean, conditions?: string, summarized?: boolean, orderField?: string, sort?: string, page?: number, limit?: number, showingFields?: string[], hidingFields?: string[], ignorePrivatePrefix?: boolean, showDeleted?: boolean) {
        const url = composeUrl(this.baseUrl, resource, isPublic, conditions, summarized, orderField, sort, page, limit, showingFields, hidingFields, false, ignorePrivatePrefix, showDeleted);
        const helper = { retry: true }
        for (let tries = 1; tries <= GET_TRIES; tries++) {
            try {
                return await doRequest(this, url, 'GET', isPublic, helper);
            } catch (err) {
                if (tries >= GET_TRIES || !helper.retry)
                    throw err
            }
        }
    }

    async request({ resource, method, isPublic, data, contentType, redirectOnFail }: RequestOptions) {
        const url = composeUrl(this.baseUrl, resource, !!isPublic);
        return doRequest(this, url, method || 'GET', !!isPublic, {}, data || {}, contentType, redirectOnFail);
    }

    async getEx({ resource, isPublic, conditions, summarized, orderField, sort, page, limit, showingFields, hidingFields, ignorePrivatePrefix, showDeleted }: RequestGETOptions) {
        return this.get(resource, !!isPublic, conditions, summarized, orderField, sort, page, limit, showingFields, hidingFields, ignorePrivatePrefix, showDeleted)
    }

    async post(resource: string, isPublic: boolean, data?: any, contentType?: string, redirectOnFail?: boolean) {
        const url = composeUrl(this.baseUrl, resource, isPublic);
        return await doRequest(this, url, 'POST', isPublic, {}, data || {}, contentType, redirectOnFail);
    }

    async postEx({ resource, isPublic, data, contentType, redirectOnFail }: RequestPOSTOptions) {
        const url = composeUrl(this.baseUrl, resource, !!isPublic);
        return doRequest(this, url, 'POST', !!isPublic, {}, data || {}, contentType, redirectOnFail);
    }

    async put(resource: string, isPublic: boolean, data?: any, contentType?: string) {
        const url = composeUrl(this.baseUrl, resource, isPublic);
        return await doRequest(this, url, 'PUT', isPublic, {}, data || {}, contentType);
    }

    async putEx({ resource, isPublic, data, contentType, redirectOnFail }: RequestPOSTOptions) {
        const url = composeUrl(this.baseUrl, resource, !!isPublic);
        return await doRequest(this, url, 'PUT', !!isPublic, {}, data || {}, contentType);
    }

    async delete(resource: string, isPublic: boolean, data?: any, contentType?: string) {
        const url = composeUrl(this.baseUrl, resource, isPublic);
        return await doRequest(this, url, 'DELETE', isPublic, {}, data || {}, contentType);
    }

    async patch(resource: string, isPublic: boolean, data?: any, contentType?: string) {
        const url = composeUrl(this.baseUrl, resource, isPublic);
        return await doRequest(this, url, 'PATCH', isPublic, {}, data || {}, contentType);
    }

    async secureGet(resource: string) {
        const url = composeUrl(this.baseUrl, resource, true);
        return await doSecureRequest(this, url, 'GET');
    }

    async securePost(resource: string, data: any, contentType?: string, ignoreRedirect?: boolean, sendToken?: boolean) {
        const url = composeUrl(this.baseUrl, resource, true);
        return await doSecureRequest(this, url, 'POST', data, contentType, ignoreRedirect, sendToken);
    }

    async secureDelete(resource: string, data: any, contentType?: string, sendToken?: boolean) {
        const url = composeUrl(this.baseUrl, resource, true);
        return await doSecureRequest(this, url, 'DELETE', data, contentType, true, sendToken);
    }
}

export interface ApiRequestHelper {
    retry?: boolean
    [key: string]: any
}