import { lazy } from 'react';
import { LocalShipping, Map, RvHookupSharp } from '@mui/icons-material';
import { Module } from '../core/types';

// ############### props from routes

import { dependencies as SalesRouteDep, options as SalesRouteOpts } from '../pages/Logistica/Rotas/route.props';
import LogisticsIcon from '@src/icons/LogisticsIcon';
import { CargasDep, CargasOptions } from '@src/pages/Logistica/Cargas/route.props';

// ############### Elements of Routes

const Cargas = lazy(() => import('../pages/Logistica/Cargas'));
const SalesRoutes = lazy(() => import('../pages/Logistica/Rotas'));
const Fornecedores = lazy(() => import('../pages/Estoque/Fornecedores'));
const Veiculos = lazy(() => import('../pages/Logistica/Veiculos'));

// ############### Routes

export const US_Logistics: Module = {
    name: 'logistics',
    routes: [
        {
            name: 'logistics', icon: <LogisticsIcon />, internalName: 'logistica',
            children: [
                { name: 'shipments', element: <Cargas />, icon: <LocalShipping />, resource: 'cargas', internalGroup: 'logistica', internalName: 'cargas', dependencies: CargasDep, options: CargasOptions },
                { name: 'routes', element: <SalesRoutes />, icon: <Map />, resource: 'rotas', dependencies: SalesRouteDep, options: SalesRouteOpts, internalGroup: 'logistica', internalName: 'rotas' },
                { name: 'carriers', element: <Fornecedores key="carriers" nome='transportadoras' grupo='logistica' tipo='L' />, icon: <RvHookupSharp />, resource: 'pessoas', internalGroup: 'logistica', internalName: 'transportadoras', },
                { name: 'veichles', element: <Veiculos />, resource: 'veiculos', internalGroup: 'logistica', internalName: 'veiculos' },
            ]
        },

    ],
}