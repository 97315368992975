import { makeStyles, createStyles } from '@mui/styles';
import { CustomTheme } from '../../../core/services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        Title: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(1) + ' !important',
            paddingBottom: '0 !important',
            fontSize: '1.25rem !important',
        },

        Actions: {
            padding: theme.spacing(2) + ' !important',
            paddingTop: '0 !important',
        },

        Content: {
            padding: theme.spacing(2) + ' !important',
            position: 'relative',
        },

        ContentFull:{
            height: '96vh',
        }
    }),
);

export default useStyles;