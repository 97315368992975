import { createSvgIcon } from "@mui/material";
import { jsx } from "react/jsx-runtime";

export const LogisticsIcon = () => {
    const paths = [
        <path key="0" d="M5.4157104,2C3.2042847,2,1.4157104,3.8779907,1.4157104,6.2000122c0,3.1499634,4,7.7999878,4,7.7999878,0,0,4-4.6500244,4-7.7999878,0-2.3220215-1.7885742-4.2000122-4-4.2000122ZM5.4157104,7.7000122c-.7885742,0-1.4285889-.6719971-1.4285889-1.5s.6400146-1.5,1.4285889-1.5,1.4285889.6719971,1.4285889,1.5-.6400146,1.5-1.4285889,1.5Z" />,
        <path key="1" d="M20,10h-3v-4h-5.6137085v8H1v5h2c0,1.6599731,1.3400269,3,3,3s3-1.3400269,3-3h6c0,1.6599731,1.3400269,3,3,3s3-1.3400269,3-3h2v-5l-3-4ZM6,20.5c-.8300171,0-1.5-.6700439-1.5-1.5,0-.8300171.6699829-1.5,1.5-1.5s1.5.6699829,1.5,1.5c0,.8299561-.6699829,1.5-1.5,1.5ZM18,20.5c-.8300171,0-1.5-.6700439-1.5-1.5,0-.8300171.6699829-1.5,1.5-1.5s1.5.6699829,1.5,1.5c0,.8299561-.6699829,1.5-1.5,1.5ZM17,14v-2.5h2.5l1.960022,2.5h-4.460022Z" />
    ]
    return jsx(createSvgIcon(paths, 'LogisticsIcon'), {})
}

export default LogisticsIcon;